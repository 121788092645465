import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"
import { useLenis } from "@studio-freight/react-lenis"
import { useLocation } from "@reach/router"
import { IoClose } from "react-icons/io5"
import ProjectFormDialog from "./ProjectFormDialog"

const menuItemVariants = {
    closed: { y: 50, opacity: 0 },
    open: i => ({
        y: 0,
        opacity: 1,
        transition: {
            delay: i * 0.1,
            duration: 0.5,
            ease: [0.33, 1, 0.68, 1]
        }
    })
}

const burgerLineVariants = {
    closed: {
        transition: { duration: 0.4, ease: [0.33, 1, 0.68, 1] }
    },
    open: {
        transition: { duration: 0.4, ease: [0.33, 1, 0.68, 1] }
    }
};

const Header = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [isFormOpen, setIsFormOpen] = useState(false)
    const [scrolled, setScrolled] = useState(false)
    const lenis = useLenis()
    const location = useLocation()
    const isHomepage = location.pathname === "/"

    const isActiveSection = (sectionId) => {
        if (!isHomepage) return false;
        const element = document.querySelector(sectionId);
        if (!element) return false;
        
        const rect = element.getBoundingClientRect();
        return rect.top <= 100 && rect.bottom >= 100;
    }

    const [activeSection, setActiveSection] = useState('');

    useEffect(() => {
        if (!isHomepage) return;

        const handleScroll = () => {
            const sections = ['#creations', '#services', '#hire-us'];
            const currentSection = sections.find(section => isActiveSection(section)) || '';
            setActiveSection(currentSection);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isHomepage]);

    const getNavLinkClass = (path) => {
        if (path === location.pathname) {
            return "text-indigo-400";
        }
        if (isHomepage && path === activeSection) {
            return "text-indigo-400";
        }
        return "text-zinc-400 hover:text-white";
    };

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 20)
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const menuVariants = {
        closed: {
            x: "100%",
            transition: {
                type: "tween",
                duration: 0.6,
                ease: [0.33, 1, 0.68, 1]
            }
        },
        open: {
            x: "0%",
            transition: {
                type: "tween",
                duration: 0.6,
                ease: [0.33, 1, 0.68, 1]
            }
        }
    }

    const handleHireUs = () => {
        setIsOpen(false) // Close mobile menu if open
        setIsFormOpen(true)
    }

    const handleNavigation = (path) => {
        setIsOpen(false)
        if (isHomepage) {
            lenis?.scrollTo(path)
        } else {
            navigate(`/${path}`)
        }
    }

    return (
        <>
            <header 
                // className={`fixed top-0 w-full z-50 transition-all duration-300 ${
                //     scrolled
                //         ? "py-6 bg-background-default/80 backdrop-blur-xl"
                //         : "py-6 bg-background-default/80 backdrop-blur-xl"
                // }`}
                className={`fixed top-0 w-full z-50 transition-all duration-300 py-4 bg-background-default/80 backdrop-blur-xl`}
            >
                <div className="container mx-auto px-6">
                    <nav className="flex items-center justify-between">
                        {/* Logo */}
                        <Link 
                            to="/"
                            className="relative z-50 flex items-center gap-4 group"
                        >
                            <motion.img 
                                src="https://ik.imagekit.io/kocdej7gl/ast_logo_new.png"
                                className="h-12 w-auto"
                                alt="AppSpectrum Logo"
                                whileHover={{ scale: 1.05 }}
                                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                            />
                            <div className="font-medium text-white leading-tight">
                                <span className="block text-sm tracking-wide">AppSpectrum</span>
                                <span className="block text-xs text-indigo-400">Technology</span>
                            </div>
                        </Link>

                        {/* Desktop Navigation */}
                        <div className="hidden lg:flex items-center gap-8">
                            {isHomepage ? (
                                <>
                                    <NavLink 
                                        onClick={() => lenis?.scrollTo("#creations")}
                                        isActive={activeSection === '#creations'}
                                    >
                                        Creations
                                    </NavLink>
                                    <NavLink 
                                        onClick={() => lenis?.scrollTo("#services")}
                                        isActive={activeSection === '#services'}
                                    >
                                        Services
                                    </NavLink>
                                    <Link 
                                        to="/about" 
                                        className={getNavLinkClass('/about')}
                                    >
                                        About
                                    </Link>
                                    <motion.button
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        onClick={handleHireUs}
                                        className={`px-6 py-2 bg-indigo-600 text-white rounded-full
                                            font-medium transition-colors hover:bg-indigo-700
                                            shadow-lg shadow-indigo-600/20 ${
                                                activeSection === '#hire-us' ? 'bg-indigo-500' : ''
                                            }`}
                                    >
                                        Hire Us
                                    </motion.button>
                                </>
                            ) : (
                                <>
                                    <Link 
                                        to="/#creations" 
                                        className={getNavLinkClass('/#creations')}
                                    >
                                        Creations
                                    </Link>
                                    <Link 
                                        to="/#services" 
                                        className={getNavLinkClass('/#services')}
                                    >
                                        Services
                                    </Link>
                                    <Link 
                                        to="/about" 
                                        className={getNavLinkClass('/about')}
                                    >
                                        About
                                    </Link>
                                    <motion.button
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        onClick={handleHireUs}
                                        className={`px-6 py-2 bg-indigo-600 text-white rounded-full
                                            font-medium transition-colors hover:bg-indigo-700
                                            shadow-lg shadow-indigo-600/20`}
                                    >
                                        Hire Us
                                    </motion.button>
                                </>
                            )}
                        </div>

                        {/* Burger Menu Button */}
                        <div className="lg:hidden relative z-50">
                            <button
                                className="w-12 h-12 relative focus:outline-none"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                <div className="block w-8 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                    <motion.span
                                        variants={burgerLineVariants}
                                        animate={isOpen ? {
                                            rotate: 45,
                                            y: [0, 8, 8],
                                            width: ["32px", "20px", "32px"],
                                        } : {
                                            rotate: 0,
                                            y: 0,
                                            width: "32px",
                                        }}
                                        className="absolute h-0.5 w-8 bg-white transform transition-transform"
                                        style={{ transformOrigin: "50% 50%" }}
                                    />
                                    <motion.span
                                        animate={isOpen ? {
                                            opacity: 0,
                                            x: 20
                                        } : {
                                            opacity: 1,
                                            x: 0
                                        }}
                                        className="absolute h-0.5 w-8 bg-white top-2.5"
                                    />
                                    <motion.span
                                        variants={burgerLineVariants}
                                        animate={isOpen ? {
                                            rotate: -45,
                                            y: [-0, -8, -8],
                                            width: ["32px", "20px", "32px"],
                                        } : {
                                            rotate: 0,
                                            y: 5,
                                            width: "32px",
                                        }}
                                        className="absolute h-0.5 w-8 bg-white transform transition-transform"
                                        style={{ transformOrigin: "50% 50%" }}
                                    />
                                </div>
                            </button>
                        </div>
                    </nav>
                </div>
            </header>

            {/* Mobile Menu */}
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={menuVariants}
                        className="fixed inset-0 bg-zinc-900/95 backdrop-blur-md z-40 lg:hidden"
                    >
                        <div className="h-full flex flex-col justify-center px-8">
                            <MobileMenuItem index={0}>
                                <Link 
                                    to="/#creations" 
                                    onClick={() => setIsOpen(false)}
                                    className={`text-4xl font-medium transition-colors ${getNavLinkClass('/#creations')}`}
                                >
                                    Creations
                                </Link>
                            </MobileMenuItem>
                            
                            <MobileMenuItem index={1}>
                                <Link 
                                    to="/#services" 
                                    onClick={() => setIsOpen(false)}
                                    className={`text-4xl font-medium transition-colors ${getNavLinkClass('/#services')}`}
                                >
                                    Services
                                </Link>
                            </MobileMenuItem>

                            <MobileMenuItem index={2}>
                                <Link 
                                    to="/about" 
                                    onClick={() => setIsOpen(false)}
                                    className={`text-4xl font-medium transition-colors ${getNavLinkClass('/about')}`}
                                >
                                    About
                                </Link>
                            </MobileMenuItem>

                            <MobileMenuItem index={3}>
                                <button
                                    onClick={handleHireUs}
                                    className="text-4xl font-medium text-white hover:text-indigo-400 transition-colors"
                                >
                                    Hire Us
                                </button>
                            </MobileMenuItem>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <ProjectFormDialog 
                isOpen={isFormOpen}
                onClose={() => setIsFormOpen(false)}
            />
        </>
    )
}

const MobileMenuItem = ({ children, index }) => (
    <motion.div
        custom={index}
        variants={menuItemVariants}
        className="mb-12"
    >
        {children}
    </motion.div>
)

const NavLink = ({ children, onClick, isActive }) => (
    <motion.div
        whileHover={{ y: -2 }}
        className="relative"
    >
        <button
            onClick={onClick}
            className={`transition-colors relative
                after:absolute after:bottom-0 after:left-0 after:w-0 after:h-0.5
                after:bg-indigo-500 hover:after:w-full after:transition-all
                ${isActive ? 'text-indigo-400' : 'text-zinc-400 hover:text-white'}`}
        >
            {children}
        </button>
    </motion.div>
)

export default Header