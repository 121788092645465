import React, { useState } from "react";
import { motion } from "framer-motion";
import { useLocation } from "@reach/router";
import ProjectFormDialog from "./ProjectFormDialog";

const Footer = ({ onEmailClick }) => {
    const [isFormOpen, setIsFormOpen] = useState(false);
    const currentYear = new Date().getFullYear();
    const location = useLocation();
    const isHomePage = location.pathname === "/";
    const isProjectDetails = location.pathname === "/project-details";

    const handleViewWorkClick = (e) => {
        e.preventDefault();
        window.location.href = '/#creations';
    };

    return (
        <footer className="relative bg-background-default" id="contact">
            {/* Gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-background-default to-background-default opacity-90"/>

            {/* CTA Section */}
            <div className="relative pt-24 pb-16">
                <div className="custom-container mx-auto px-4 sm:px-6 lg:px-8">
                    <motion.div
                        initial={{ y: 20, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        viewport={{ once: true }}
                        className="relative"
                    >
                        <div className="max-w-3xl mx-auto text-center">
                            <h2 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-white via-white to-zinc-400 bg-clip-text text-transparent">
                                Ready to Transform Your Vision?
                            </h2>
                            <p className="text-lg text-zinc-400 mb-8">
                                Let's collaborate to create something extraordinary together.
                            </p>
                            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
                                <button
                                    onClick={() => setIsFormOpen(true)}
                                    className="px-8 py-3 rounded-full bg-white text-zinc-900 font-medium hover:bg-zinc-100 transition-colors"
                                >
                                    Start a Project
                                </button>
                                {!isHomePage && (
                                    <button
                                        onClick={handleViewWorkClick}
                                        className="text-zinc-400 hover:text-white transition-colors"
                                    >
                                        View Our Work →
                                    </button>
                                )}
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>

            {/* Main Footer */}
            <div className="relative border-t border-zinc-800/50">
                <div className="custom-container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
                        {/* Brand Column */}
                        <div className="space-y-4">
                            <img
                                src="https://ik.imagekit.io/kocdej7gl/ast_logo_new.png"
                                alt="AppSpectrum Technology"
                                className="h-8"
                            />
                            <p className="text-sm text-zinc-400">
                                Crafting digital experiences that inspire and innovate.
                            </p>
                        </div>

                        {/* Navigation Links */}
                        <div className="flex flex-col items-start">
                            <h3 className="text-sm font-medium text-white mb-4">Links</h3>
                            <div className="flex space-x-2">
                                <div className="flex gap-2">
                                    <span>
                                        <a
                                            href="/#services"
                                            className="text-sm text-zinc-400 hover:text-white transition-colors inline-block"
                                        >
                                            Services
                                        </a>
                                    </span>
                                    <span>
                                        <a
                                            href="/#creations"
                                            className="text-sm text-zinc-400 hover:text-white transition-colors inline-block"
                                        >
                                            Projects
                                        </a>
                                    </span>
                                </div>

                                <div className="flex gap-2">
                                    <span>
                                        <a
                                            href="/about"
                                            className="text-sm text-zinc-400 hover:text-white transition-colors inline-block"
                                        >
                                            About
                                        </a>
                                    </span>
                                    <span>
                                        <button
                                            onClick={() => setIsFormOpen(true)}
                                            className="text-sm text-zinc-400 hover:text-white transition-colors inline-block"
                                        >
                                            Contact Us
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Copyright */}
                    <div className="mt-12 pt-8 border-t border-zinc-800/50">
                        <p className="text-sm text-zinc-500 text-center">
                            © {currentYear} AppSpectrum Technology. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>

            <ProjectFormDialog
                isOpen={isFormOpen}
                onClose={() => setIsFormOpen(false)}
            />
        </footer>
    );
};

export default Footer;