import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoClose } from 'react-icons/io5';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase'; // Make sure this import points to your Firebase config

const ProjectFormDialog = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        projectType: '',
        message: ''
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            // Add submission to Firestore
            await addDoc(collection(db, 'projectInquiries'), {
                ...formData,
                timestamp: serverTimestamp(),
                status: 'new'
            });
            
            setSubmitStatus('success');
            setTimeout(() => {
                onClose();
                setSubmitStatus(null);
                setFormData({
                    name: '',
                    email: '',
                    company: '',
                    projectType: '',
                    message: ''
                });
            }, 2000);
            
        } catch (error) {
            console.error('Error submitting form:', error);
            setSubmitStatus('error');
        } finally {
            setIsSubmitting(false);
        }
    }, [formData, onClose]);

    const handleChange = useCallback((e) => {
        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    }, []);

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/60 backdrop-blur-lg"
                >
                    <motion.div
                        initial={{ y: 20, scale: 0.95 }}
                        animate={{ y: 0, scale: 1 }}
                        exit={{ y: 20, scale: 0.95 }}
                        transition={{ 
                            type: "spring",
                            stiffness: 300,
                            damping: 30
                        }}
                        className="bg-zinc-900/70 backdrop-blur-xl rounded-3xl w-full max-w-xl p-8 relative border border-zinc-800/50"
                    >
                        <button
                            onClick={onClose}
                            className="absolute right-6 top-6 text-zinc-400 hover:text-white transition-colors"
                        >
                            <IoClose size={24} />
                        </button>

                        <h2 className="text-3xl font-bold text-white-custom-light mb-2">Start Your Project</h2>
                        <p className="text-zinc-400 mb-8">Tell us about your vision, and we'll help bring it to life.</p>

                        <form onSubmit={handleSubmit} className="space-y-6">
                            {/* Name and Email fields */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-zinc-300">Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        required
                                        className="w-full bg-zinc-800/50 border border-zinc-700/50 rounded-xl px-4 py-3 text-white placeholder-zinc-500 focus:border-primary-default/50 focus:ring-1 focus:ring-primary-default/50 transition-all"
                                        onChange={handleChange}
                                        value={formData.name}
                                        placeholder="John Doe"
                                    />
                                </div>
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-zinc-300">Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        required
                                        className="w-full bg-zinc-800/50 border border-zinc-700/50 rounded-xl px-4 py-3 text-white placeholder-zinc-500 focus:border-primary-default/50 focus:ring-1 focus:ring-primary-default/50 transition-all"
                                        onChange={handleChange}
                                        value={formData.email}
                                        placeholder="john@example.com"
                                    />
                                </div>
                            </div>

                            {/* Company field */}
                            <div className="space-y-2">
                                <label className="block text-sm font-medium text-zinc-300">Company</label>
                                <input
                                    type="text"
                                    name="company"
                                    className="w-full bg-zinc-800/50 border border-zinc-700/50 rounded-xl px-4 py-3 text-white placeholder-zinc-500 focus:border-primary-default/50 focus:ring-1 focus:ring-primary-default/50 transition-all"
                                    onChange={handleChange}
                                    value={formData.company}
                                    placeholder="Your Company Name"
                                />
                            </div>

                            {/* Project Type field */}
                            <div className="space-y-2">
                                <label className="block text-sm font-medium text-zinc-300">Project Type</label>
                                <select
                                    name="projectType"
                                    className="w-full bg-zinc-800/50 border border-zinc-700/50 rounded-xl px-4 py-3 text-white focus:border-primary-default/50 focus:ring-1 focus:ring-primary-default/50 transition-all"
                                    onChange={handleChange}
                                    value={formData.projectType}
                                >
                                    <option value="">Select project type</option>
                                    <option value="mobile-app">Mobile App</option>
                                    <option value="web-app">Web Application</option>
                                    <option value="website">Website</option>
                                    <option value="design">UI/UX Design</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>

                            {/* Project Details field */}
                            <div className="space-y-2">
                                <label className="block text-sm font-medium text-zinc-300">Project Details</label>
                                <textarea
                                    name="message"
                                    rows="4"
                                    className="w-full bg-zinc-800/50 border border-zinc-700/50 rounded-xl px-4 py-3 text-white placeholder-zinc-500 focus:border-primary-default/50 focus:ring-1 focus:ring-primary-default/50 transition-all"
                                    onChange={handleChange}
                                    value={formData.message}
                                    placeholder="Tell us about your project..."
                                ></textarea>
                            </div>

                            {/* Submit button */}
                            <motion.button
                                type="submit"
                                disabled={isSubmitting}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                className={`w-full py-4 rounded-xl font-medium transition-all ${
                                    isSubmitting 
                                        ? 'bg-zinc-600 cursor-not-allowed' 
                                        : 'bg-primary-default hover:bg-primary-default/90'
                                } text-white shadow-lg shadow-primary-default/20`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit Project Inquiry'}
                            </motion.button>

                            {/* Status messages */}
                            {submitStatus === 'success' && (
                                <motion.div
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    className="text-green-400 text-center bg-green-400/10 p-4 rounded-xl"
                                >
                                    Thank you! We'll get back to you soon.
                                </motion.div>
                            )}

                            {submitStatus === 'error' && (
                                <motion.div
                                    initial={{ opacity: 0, y: 10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    className="text-red-400 text-center bg-red-400/10 p-4 rounded-xl"
                                >
                                    Something went wrong. Please try again.
                                </motion.div>
                            )}
                        </form>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default React.memo(ProjectFormDialog);
