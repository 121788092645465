import * as React from "react"
import Header from "./header"
import "../styles/global.css"
import ReactLenis from "@studio-freight/react-lenis";
import {useEffect, useRef} from "react";
import {getAnalytics, logEvent, isSupported} from "firebase/analytics";
import {useLocation} from "@reach/router";
import {app} from "../firebase";
import Footer from "./footer";

const Layout = ({ children }) => {

    const location = useLocation()

    const cursorRef = useRef(null);

        const logEmailAction = async () => {
            const isSup = await isSupported()
            if (isSup) {
                const analytics = getAnalytics(app);
                logEvent(analytics, 'email_click');
            }
        }

    useEffect( () => {
        // Log the event when the component mounts

        const analyticsFunc = async () => {
            const isSup = await isSupported()
            if (isSup) {
                const analytics = getAnalytics(app);
                logEvent(analytics, 'page_view', {
                    page_path: location.pathname,
                    page_title: document.title
                });
            }
        }

        analyticsFunc();

    }, [location.pathname]);

    return (
        <div className="relative min-h-screen">
            <div className="relative z-10">
                <div className={"cursor-follower"} ref={cursorRef} />
                <ReactLenis root lerp={0.2} >
                    <Header/>
                    <main id={"z-30"}>
                        {children}
                    </main>
                    <Footer onEmailClick={logEmailAction}/>
                </ReactLenis>
            </div>
        </div>
    )
}

export default Layout
